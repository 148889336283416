

import React from "react";
import CountryService from '../services/country';
import AuthorizationCheck from '../auth/authorizationCheck';
import SupplierService from '../services/supplier';
import Utility from '../utilities/utility';
import DatePiCker from 'react-datepicker';
import PaymentService from "../services/payment";
import $ from 'jquery';

class PaymentList extends React.Component {

    constructor(props) {

        super(props);

        this.countryService = new CountryService();
        this.paymentService = new PaymentService();


        this.state = {

            fromDate: '',
            toDate: '',
            payments: [],
            countries: [],
            suppliers: [],
            currentUserRole: ''
        };
    }


    componentDidMount() {

        this.setFromDate(new Date());
        this.setToDate(new Date());

        try {

            const countryRequest = this.countryService.fetchCountries();
            const supplierRequest = SupplierService.supplierList();

            Promise.all([countryRequest, supplierRequest])
                .then((responseData) => {



                    if (!Utility.isEmpty(responseData[0]) && responseData[0].status == 200) {


                        responseData[0].json().then(response => {


                            this.setState({ countries: response });

                        })
                    }

                    if (!Utility.isEmpty(responseData[1]) && responseData[1].status == 200) {




                        responseData[1].json().then(response => {


                            this.setState({ suppliers: response });

                        })
                    }



                })

        } catch (error) {

            console.log(error);
        }
    }

    setRole = (role) => {

        this.setState({ currentUserRole: role });
    }

    setFromDate = (date) => {

        this.setState({ fromDate: date })
    }

    setToDate = (date) => {

        this.setState({ toDate: date })
    }

    resetDate = () => {

        this.setFromDate('');
        this.setToDate('');
    }

    getPayments = async() => {

        let fromDate = $('#fromDate').val();
        let toDate = $('#dateTo').val();
        let countryId = $('#countryId').val();
        let supplierId = $('#supplierId').val();


        const response = await this.paymentService.paymentList(countryId, supplierId, fromDate, toDate);

        this.setState({
            payments : await response.json()
        });
    }


    render() {

        const countries = this.state.countries;

        const payments = this.state.payments;

        console.log(payments);

        return (

            <div className="container">

                <AuthorizationCheck accessLevel='MANAGER' userRole={this.setRole} />

                <div className="form-wrapper">

                    <h2>Liste de Paiements</h2>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="label">Choisi le Pays</label>
                                <select className="form-control" id="countryId" onChange={this.getPayments}
                                    onClick={this.resetDate}>
                                    <option></option>
                                    {countries.length > 0 && countries.map(country =>

                                        <option value={country.countryId}>{country.name}</option>
                                    )}
                                </select>
                            </div>

                            <div className="form-group">
                                <label className="label">Fournisseurs</label>
                                <select className="form-control" id="supplierId" onChange={this.getPayments}>
                                    <option></option>
                                    {this.state.suppliers.length > 0 && this.state.suppliers.map(supplier =>

                                        <option value={supplier.supplierId}>{supplier.name}</option>
                                    )
                                    }
                                </select>
                            </div>
                        </div>
                    </div>


                   
                   <div className="row">

                        <div className="col">
                            <div className="form-group">
                                <DatePiCker id="fromDate" dateFormat="yyyy-MM-dd" selected={this.state.fromDate} placeholderText="De" className="form-control" onChange={this.setFromDate} />
                            </div>
                        </div>
                        <div className="col">

                            <div className="form-group">
                                <DatePiCker id="dateTo" dateFormat="yyyy-MM-dd" selected={this.state.toDate} placeholderText="Jusqua" className="form-control" onChange={this.setToDate} />
                            </div>
                        </div>

                    </div>

                    <div className="form-group">
                        <button className="btn btn-primary" onClick={this.getPayments}>Rechercher</button>
                    </div> 


                </div>

                {payments.length > 0 && payments.map(payment =>

                    <div className="card card-default mt-2" key={payment.id}>

                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    { payment.createdAt }
                                </div>

                                <div className="col">
                                { payment.description }
                                </div>

                                <div className="col">
                                { payment.amount }
                                </div>

                            </div>

                           
                        </div>

                    </div>
                )}




            </div>

        );
    }

}

export default PaymentList;