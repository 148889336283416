import React from "react";
import PaymentService from '../services/payment';
import OrderService from '../services/order';
import $ from 'jquery';
import Utility from '../utilities/utility';
import NumberFormat from 'react-number-format';
import AuthorizationCheck from "../auth/authorizationCheck";

class Payment extends React.Component {

    constructor(props) {

        super(props);

        const queryParams = new URLSearchParams(window.location.search);
        this.state = {
            suppliers: [],
            balance: [],
            orderId: queryParams.get('orderId'),
            currentUserRole: ''
        }

        if (this.state.orderId == undefined) {

            window.location.href = "/orders";
        }

        this.paymentService = new PaymentService();
    }

    componentDidMount() {

        this.suppliers();

    }

    setRole = (role) => {

        this.setState({ currentUserRole: role });
    }


    addPayment = async () => {

        try {

            let supplierId = $('#supplierId').val();
            let desc = $('#description').val();
            let amount = $('#amount').val();

            if (Utility.isEmpty(supplierId) || Utility.isEmpty(amount) || amount == 0) {

                $('.messageHolder').html(
                    '<div class="alert alert-danger message-alert">Le forunisseur et le montant doivent etre fournis</div>'
                );

                $('.message-alert').delay(3000).fadeOut('slow');

                return;
            }


            const response = await this.paymentService.addPayment(this.state.orderId, supplierId, desc, amount);
            const paymentData = await response.json();

            if (Utility.isEmpty(paymentData)) {

                return;
            }

            this.setState({
                balance: paymentData.supplierOrderBalance
            })

            $('#description').val("");
            $('#amount').val("");

        } catch (error) {
            console.log(error);
        }


    }

    suppliers = async () => {



        const response = await OrderService.orderSuppliers(this.state.orderId);

        this.setState({

            suppliers: await response.json()
        })

    }


    orderSupplierBalance = async () => {



        let supplierId = $('#supplierId').val();
        const response = await OrderService.orderSupplierBalance(this.state.orderId, supplierId);

        this.setState({

            balance: await response.json()
        })

    }


    render() {



        return (

            <div className="payment-wrapper mt-3">

                <AuthorizationCheck accessLevel='MANAGER' userRole={this.setRole} />


                <h2>Ajoute Payment</h2>


                <div className="container paymentHolder">

                    <div className="form-wrapper mt-4">

                        <div className="messageHolder"></div>

                        <div className="card">
                            <div className="card-header">Balance</div>
                            <div className="card-body">
                                <NumberFormat
                                    defaultValue={0}
                                    value={this.state.balance.balance}
                                    thousandSeparator={true}
                                    displayType="text" />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="label">Fournisseurs</label>
                            <select className="form-control" id="supplierId" onChange={this.orderSupplierBalance}>
                                <option></option>
                                {this.state.suppliers.length > 0 && this.state.suppliers.map(supplier =>

                                    <option value={supplier.supplierId}>{supplier.name}</option>
                                )
                                }
                            </select>
                        </div>

                        <div className="form-group">
                            <label className="label">Montant</label>
                            <input className="form-control" id="amount" />
                        </div>

                        <div className="form-group">
                            <label className="label">Description</label>
                            <input className="form-control" id="description" />
                        </div>

                        <div className="form-group">
                            <button className="btn btn-primary full-width" onClick={this.addPayment}>Ajouter</button>
                        </div>

                    </div>

                </div>

            </div>
        )
    }
}

export default Payment;