
const baseUrl = process.env.REACT_APP_BASE_URL;

class PaymentService{


    addPayment(orderId, supplierId, description, amount){


        const reqData = {
            "orderId": orderId,
            "supplierId": supplierId,
            "description": description,
            "amount" : amount

        }; 
        const token = localStorage.getItem('token');
        

        return fetch(baseUrl+'/admin/payment/add', {
            method : 'POST',
            headers: {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            },
            body: JSON.stringify(reqData)
        })
            
        .catch(error => console.log(error))
    }



    paymentList(countryId, supplierId, fromDate, toDate){


        const reqData = {
            "countryId": countryId,
            "supplierId": supplierId,
            "fromDate": fromDate,
            "toDate" : toDate

        }; 
        const token = localStorage.getItem('token');
        

        return fetch(baseUrl+'/admin/payment/list', {
            method : 'POST',
            headers: {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : token,
                'Accept' : 'application/json',
            },
            body: JSON.stringify(reqData)
        });
            
        
    }



}

export default PaymentService;